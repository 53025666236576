// 按需加载
import * as echarts from 'echarts/lib/echarts'
// 引入折线图
import 'echarts/lib/chart/line'
import 'echarts/lib/coord/cartesian/Axis2D'
import 'echarts/lib/coord/cartesian/Grid'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
// import 'echarts/lib/coord/CoordinateSystem'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/grid'
import 'echarts/lib/component/toolbox'
// import 'echarts/lib/component/axis'
window.echarts = echarts
export default echarts
