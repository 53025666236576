import ddc from '@/http/ddc'
import type { StatData, StatContentData } from './models/stats'

/**
 * @description 业务线查询
 */
export function checkBusinessLine(params: {}) {
  return ddc.get('/analytics/xgb/businessline', { params })
}

/**
 * @description 业务线指标
 */
export function fetchBusinessStats(body: {}): Promise<StatData> {
  return ddc.post('/analytics/xgb/blStatistics', body)
}

/**
 * @description 平台指标
 */
export function fetchPlatformStats(body: {}): Promise<StatData> {
  return ddc.post('/analytics/xgb/appStatistics', body)
}

/**
 * @description 内容分析
 */
export function fetchContentStats(body: {}): Promise<StatContentData> {
  return ddc.post('/analytics/xgb/contentStatistics', body)
}

/**
 * @description 用户分析
 */
// export function fetchUserAboutStats(body: {}): Promise<StatData> {
//   return ddc.post('/analytics/xgb/ContentUserListStatistics', body)
// }

// 文章推送列表
export function fetchArticlePushList(params): Promise<any> {
  return ddc.get('/analytics/article_push/articles', { params })
}
// 文章推送领先率
export function fetchArticlePushRate(params): Promise<any> {
  return ddc.get('/analytics/article_push/lead_rate', { params })
}