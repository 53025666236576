import echarts from '@/common/lib/echarts'
import { onMounted, onUnmounted } from 'vue'

export const shortcuts = [
  {
    text: '最近7天',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
      return [start, end]
    })()
  },
  {
    text: '最近30天',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
      return [start, end]
    })()
  },
  {
    text: '最近90天',
    value: (() => {
      const end = new Date()
      const start = new Date()
      start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
      return [start, end]
    })()
  }
]

export type TimeScale = 'day' | 'month' | 'week'

export const defaultBetweenTime = [
  new Date(new Date().setHours(0, 0, 0, 0) - 86400 * 30000),
  new Date(new Date().setHours(0, 0, 0, 0))
]

export function sortxAxis(xaxis: string[]): string[] {
  xaxis.sort((L, R) => {
    return new Date(`2020-${L}`).getTime() - new Date(`2020-${R}`).getTime()
  })
  return xaxis
}

export function useHandleChartsInit(selector, theme = 'light'): any {
  const refChart = { value: {} } as { value: any }
  onMounted(() => {
    console.log('chartIns::ok')
    const dom = document.querySelector(selector) as HTMLDivElement
    if (!dom) return
    refChart.value = echarts.init(dom, theme)
  })
  onUnmounted(() => {
    if (refChart.value && refChart.value.dispose) {
      console.log('chartIns::no')
      refChart.value.dispose()
    }
  })
  return refChart
}

export function calcxAxis(linesData: { data: string[] }[] = []): string[] {
  if (linesData.length) {
    let idx = 0
    let len = 0
    linesData.forEach((line, idx_) => {
      if (line.data.length > len) {
        len = line.data.length
        idx = idx_
      }
    })
    return linesData[idx].data
      .map((line) => {
        if (line[0]) return line[0]
        return ''
      })
      .filter((axis) => !!axis)
  }
  return []
}